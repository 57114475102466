import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { useState } from 'react';
import { ButtonPrimary, Title } from './StyledComponents';

export const ContactUsStyled = styled.section`
    padding: 32px 0px 64px;
    textarea {
        margin-bottom: 24px;
    }
    button {
        width: 100%;
    }
    .main-image {
        margin-bottom: 64px;
        height: 343px;
        border-radius: 15px;
    }
    @media ${({ theme }) => theme.device.laptop} {
        display: flex;
        flex-direction: row;
        gap: 55px;
        justify-content: space-between;
        .main-image {
            width: 548px;
            height: 548px;
        }
        > div + div {
            width: 607px;
            position: relative;
            bottom: 20px;
        }
    }
    @media ${({ theme }) => theme.device.laptopL} {
        gap: 135px;
        > div + div {
            width: 517px;
        }
    }
`;

const Form = styled.form`
    label {
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: ${({ theme }) => theme.colors.gray5};
    }
    fieldset {
        border: none;
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 0;
        margin: 0 0 32.2px 0;
        label {
            position: relative;
            right: 8px;
        }
        input {
            outline: none;
        }
        input:focus {
            outline: none;
        }
        p {
            margin: 0;

            padding: 0;
        }
        div {
            gap: 16px;
            display: flex;
            align-items: center;
        }
    }
    textarea {
        margin-top: 10px;
        margin-bottom: 24px;
    }
    input {
        margin: 10px 0;
    }
    input,
    textarea {
        outline: 1px solid white;
        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 5px;
        border: 2px solid #929ea4;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.gray5};
    }
    input:-webkit-autofill {
        -webkit-text-fill-color: ${({ theme }) => theme.colors.gray5} !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        border-radius: 5px;
    }
    input:focus {
        color: ${({ theme }) => theme.colors.gray5};
        border: 2px solid ${({ theme }) => theme.colors.blue3};
        border-radius: 5px;
    }

    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
    }

    textarea:focus {
        border: 2px solid ${({ theme }) => theme.colors.blue3};
        /* oranges! yey */
    }
    @media ${({ theme }) => theme.device.laptop} {
        fieldset {
            gap: 36px;
            margin: 0 0 16px 0;
        }
        display: flex;
        flex-direction: column;
        button {
            align-self: flex-end;
            width: 174px;
        }
    }
`;

export const ContactUs: React.FC = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [submitState, setSubmitState] = useState<string>();
    const [clicked, setClicked] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce((obj, key) => {
            const value = formData.getAll(key).join(', ').trim();
            if (value) {
                obj[key] = value;
            }
            return obj;
        }, {} as Record<string, unknown>);

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };

    return (
        <ContactUsStyled className="large-container">
            <StaticImage
                className="main-image"
                src="../../images/common-asset/contact-us.jpg"
                alt="children hi fixing dentist"
                quality={80}
            />
            <div>
                <Title side="left" className="header-2 underline">
                    Contact Us
                </Title>
                <Form className={`${clicked && 'submitted'}`} onSubmit={onSubmit}>
                    <fieldset>
                        <p>Location*</p>
                        <div>
                            <input
                                type="radio"
                                id="santa-clarita"
                                name="location"
                                value="Santa Clarita"
                                required
                            />
                            <label htmlFor="santa-clarita">Santa Clarita</label>
                        </div>
                        <div>
                            <input
                                type="radio"
                                id="porter-ranch"
                                name="location"
                                value="Porter Ranch"
                                required
                            />
                            <label htmlFor="porter-ranch">Porter Ranch</label>
                        </div>
                    </fieldset>

                    <label htmlFor="name">
                        Full Name*
                        <input placeholder="John Doe" type="text" name="name" id="name" required />
                    </label>
                    <label htmlFor="email">
                        Email*
                        <input
                            placeholder="johndoe@email.com"
                            type="email"
                            name="email"
                            id="email"
                            required
                        />
                    </label>
                    <label htmlFor="tel">
                        Phone
                        <input
                            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                            required
                            placeholder="123-456-7890"
                            type="phone"
                            name="phone"
                            id="phone"
                        />
                    </label>
                    <label htmlFor="message">
                        Message
                        <textarea name="message" id="message" required />
                    </label>
                    <ButtonPrimary
                        type="submit"
                        disabled={submitState === 'submitting'}
                        color="blue"
                        fill
                    >
                        {submitState === 'submitting' ? 'Sending...' : 'Send Message'}
                    </ButtonPrimary>
                    <p
                        style={{
                            position: 'relative',
                            bottom: '36px',
                            fontWeight: 'bold',
                            color: `#0096D7`,
                            transition: 'opacity .2s ease-in',
                            opacity: `${submitState === 'submitted' ? '1' : '0'}`,
                        }}
                    >
                        Your message has been sent!
                    </p>
                </Form>
            </div>
        </ContactUsStyled>
    );
};
