export const information = {
    santaClarita: {
        location: '18580 Via Princessa Suite 3, Santa Clarita, CA 91387',
        locationUrl:
            'https://www.google.com/maps/place/Kidz+Dental+Care/@34.4029886,-118.4611729,17z/data=!3m1!4b1!4m6!3m5!1s0x80c289a4da7c57a1:0x4c311ee1864ab811!8m2!3d34.4029842!4d-118.4589789!16s%2Fg%2F11g07b2_9l',
        phone: '(661) 388-0499',
    },
    porterRanch: {
        location: '11239 Tampa Ave #208 Porter Ranch, CA 91326',
        locationUrl:
            'https://www.google.com/maps/place/Kidz+Dental+Care/@34.2742765,-118.557091,15z/data=!4m6!3m5!1s0x80c29b4aa06e59b1:0x5fdbb00832ed863d!8m2!3d34.2742765!4d-118.557091!16s%2Fg%2F1thnp0pm',
        phone: '(818) 368-6266',
    },
    newHall: {
        mail: 'officenh@kidzdentalcare661.com',
        phone: '(661) 200-0220',
        fax: '(661) 200-0229',
    },
    email: 'office@kidzdentalcare661.com',
};

export const serviceCategories = [
    'Cleanings & Prevention',
    'Periodontal Disease',
    'Restorations',
    'Orthodontics',
    'Oral & Maxillofacial Surgery',
    'Pediatric Dentistry',
    'Surgical Instructions',
    'Other Procedures',
];
